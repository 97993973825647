import * as React from 'react'
import { Link } from 'gatsby'
import { Block } from '@thesisedu/web/dist'
import { BodyLarger, Container, LargeH1 } from '../components/styled'
import { SEO } from '../components/SEO'

const NotFoundPage = () => (
  <Container>
    <SEO title={'Page Not Found'} />
    <Block marginTop={'@size-section-md'} marginBottom={'@size-xxl'}>
      <LargeH1 isBlock={false}>Page Not Found</LargeH1>
    </Block>
    <BodyLarger>
      Uh oh! We couldn't find that page. Try <Link to={'/'}>going back to the homepage.</Link>
    </BodyLarger>
  </Container>
)

export default NotFoundPage
